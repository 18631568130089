<template>
  <div class="video_box">

    <video class="video_view" v-show="showFlv" autoplay controls id="videoElement"></video>

    <div class="m3u8_box" v-show="showM3u8">
      <video
        ref="video"
        id="videoElement"
        v-show="showM3u8"
        class="video_view video-js vjs-default-skin vjs-big-play-centered"
        controls
      >
        <source v-show="showM3u8" :src="videoUrl" id="targetVideo" />
      </video>
    </div>
    
    <div class="btn_box">
      <button class="choose_btn" @click="showVedioList()">选择监控</button>
    </div>

    <div class="videoListBox" v-show="isShowVedioList">
      <div 
        class="video_item sub_color" 
        :class="{ activeBtn: btnIndex == index }"
        v-for="(item, index) in videoList" :key="index" 
        @click="chooseVedio(item.url, index)"
      >
        {{ item.title }}
      </div>

      <!-- <el-tabs v-model="activeName">
        <el-tab-pane v-for="(item, index1) in videoList" :key="index1" :label="item.label" :name="index1 + ''">
          <div 
            class="video_item" 
            :class="{ activeBtn: btnIndex == index2 }"
            v-for="(VideoItem, index2) in item.videoList" :key="index2" 
            @click="chooseVedio(VideoItem.src, index2)"
          >
            {{ VideoItem.title }}
          </div>
        </el-tab-pane>
      </el-tabs> -->
    </div>
  </div>
</template>

<script>
  import flvjs from 'flv.js'

  export default {
    name: 'PersonalPlayVideo',

    data() {
      return {
        activeName: '0',
        isShowVedioList: false,

        showFlv: false,
        showM3u8: true,

        player: null, // 用来存储当前 video
        btnIndex: 0,

        videoUrl: '',

        videoList: [
          {
            title: '羊棚内(1)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_10_2.m3u8?expire=1722079055&id=606928606450421760&t=bdf1176bc1e4a3898db5fa1403ebaac6b5a2400194e0b2294d4b5031b9ad3d62&ev=100'
          },
          {
            title: '羊棚内(2)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_12_2.m3u8?expire=1722079136&id=606928943169142784&t=e89978e144b9206aabd671ff6574b18ab9403e9aede5ec356754cc7395ca6138&ev=100'
          },
          {
            title: '羊棚内(3)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_5_2.m3u8?expire=1722079273&id=606929517415510016&t=0643c70bf3b6dd2b74e4b73704b95812cd300af4fe34cc506730a0599c328e97&ev=100'
          },
          {
            title: '羊棚内(4)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_9_2.m3u8?expire=1722079366&id=606929908880887808&t=d42e3ef9706c7972b108dbbba58b49820202675d7072b0ebd7f5734fcc5a2217&ev=100'
          },
          {
            title: '棚外(东)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_13_2.m3u8?expire=1722079149&id=606928998341029888&t=dc3a2c45dbada73e8fdf01f5aa1c33a44694dbaaaa19550872e20cdff0e55844&ev=100'
          },
          {
            title: '羊棚内(5)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_16_2.m3u8?expire=1722079192&id=606929179581087744&t=0634f6e51b5ea9dfd8c0659aa9bb3d47f0525bd71365a04d1aa8e8a13edc0afa&ev=100'
          },
          {
            title: '玉米地(1)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_2_2.m3u8?expire=1722079224&id=606929313110962176&t=223c6ce9d6d75cc6d36c506f85705e975d22990223c61c3c53f21c29db1c7c68&ev=100'
          },
          {
            title: '玉米地(2)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_4_2.m3u8?expire=1722079260&id=606929463116042240&t=6aa90c06d58b8e22aaadba2fe772fa9381627f8d7a7a6bb70c58c13aa049b3ea&ev=100'
          },
          
          {
            title: '玉米地(3)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_6_2.m3u8?expire=1722079321&id=606929722097532928&t=d73ed1e6f25ce1cb4f432f277b1c3c828417092abe7126e5f18885a788b24f38&ev=100'
          },
          {
            title: '玉米地(4)',
            url: 'https://open.ys7.com/v3/openlive/L41591059_7_2.m3u8?expire=1722079333&id=606929770361393152&t=f61ffec7ad0e8810593c692821b514d4091f728fd236a85c909ccf53df3202bf&ev=100'
          },
          // {
          //   title: '监控1',
          //   url: 'https://open.ys7.com/v3/openlive/L41591059_1_2.m3u8?expire=1722079027&id=606928487277662208&t=45eab4ad44dcb3b720ec9372f0cede7724e8c5a6ddbb4d9e8deb6ceeaf3efba6&ev=100'
          // },
          // {
          //   title: '监控2',
          //   url: 'https://open.ys7.com/v3/openlive/L41591059_11_2.m3u8?expire=1722079089&id=606928746712141824&t=805d6687601ab57c79778826f6e0aace84d212a6a11e5d69702fe2d368e574af&ev=100'
          // },
          // {
          //   title: '监控3',
          //   url: 'https://open.ys7.com/v3/openlive/L41591059_14_2.m3u8?expire=1722079936&id=606932300478816256&t=a80b49d4108d3fdd97f71ef26783f564cfb822f352abbabb2f05367bf5a20ad2&ev=100'
          // },
          // {
          //   title: '监控4',
          //   url: 'https://open.ys7.com/v3/openlive/L41591059_15_2.m3u8?expire=1722079180&id=606929128096018432&t=ca6dd84722d97b5de19d6468fdeb96efce88cb2ea0af3a05a8e6b203b12110ca&ev=100'
          // },
          // {
          //   title: '监控5',
          //   url: 'https://open.ys7.com/v3/openlive/L41591059_3_2.m3u8?expire=1722079243&id=606929391770931200&t=6ce17df336a1d4dd2d1d3cc4858c62b8015e7beeee9710d6639d1afb754aab06&ev=100'
          // },
          // {
          //   title: '监控6',
          //   url: 'https://open.ys7.com/v3/openlive/L41591059_8_2.m3u8?expire=1722079345&id=606929819132768256&t=88619937c5ab80d489a4578b42eca12a94affd39f580a86c6393e9d491402c80&ev=100'
          // },
          
        ]
      }
    },

    mounted() { 
      // this.getVideoList()

      this.initVideo()

      let videoUrl = 'https://open.ys7.com/v3/openlive/L41591059_10_2.m3u8?expire=1722079055&id=606928606450421760&t=bdf1176bc1e4a3898db5fa1403ebaac6b5a2400194e0b2294d4b5031b9ad3d62&ev=100'

      this.playVedio(videoUrl)

      let that = this
      that.chooseVedio(videoUrl, 0)
    },

    watch: {
      // videoList: {
      //   handler(newVal, oldVal) {
      //     this.videoUrl = ''

      //     this.btnIndex = 0

      //     this.playVedio(newVal[0].url)
      //   }
      // },
    },

    methods: {
      // 获取 Vedio List 
      getVideoList() {
        this.$bus.$on('sendVideoList', (val) => {
          // console.log('获取 Vedio List : ', val);
          this.isShowVedioList = false
          this.videoList = val
          this.url = val[0].url
        })
      },

      chooseVedio(src) {
        var myPlayer = videojs(this.$refs.video);

        myPlayer.src([
          {
            type: "application/x-mpegURL",
            src
          }
        ]);

        myPlayer.play();
      },

      initVideo() {
        this.player = this.$video(this.$refs.video, { autoplay: true })
      },

      // 显示监控列表
      showVedioList() {
        this.isShowVedioList = true
      },
      
      // 播放初始视频
      playVedio(src) {
        this.btnIndex = 0

        let formatIndex = src.search(/\?/i);

        let format = src.slice(formatIndex-4, formatIndex)

        // console.log(format);    // m3u8 .flv

        // 播放 .flv 格式的视频
        if (format == '.flv') {
          this.showFlv = true
          this.showM3u8 = false

          var flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: src
          });

          var videoElement = document.getElementById('videoElement');

          flvPlayer.attachMediaElement(videoElement);
          flvPlayer.load();
          flvPlayer.play();

        } else {
          this.showFlv = false
          this.showM3u8 = true

          // 播放 .m3u8 格式的视频
          let options = {
            src,
            autoplay: true,
            type: 'application/x-mpegURL'
          }

          this.player.src(options);
          this.player.load();

          this.player.autoplay();

          let videoUrl = 'https://open.ys7.com/v3/openlive/L41591059_10_2.m3u8?expire=1722079055&id=606928606450421760&t=bdf1176bc1e4a3898db5fa1403ebaac6b5a2400194e0b2294d4b5031b9ad3d62&ev=100'
          this.chooseVedio(videoUrl, 0);
        }
      },

      // 切换视频
      chooseVedio(src, index2) {

        this.isShowVedioList = false
        this.btnIndex = index2

        let formatIndex = src.search(/\?/i);

        let format = src.slice(formatIndex-4, formatIndex)

        // console.log(format);    // m3u8 .flv

        // 播放 .flv 格式的视频
        if (format == '.flv') {
          this.showFlv = true
          this.showM3u8 = false

          var flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: src
          });

          var videoElement = document.getElementById('videoElement');

          flvPlayer.attachMediaElement(videoElement);
          flvPlayer.load();
          flvPlayer.play();

        } else {
          this.showFlv = false
          this.showM3u8 = true

          // 播放 .m3u8 格式的视频
          let options = {
            src,
            autoplay: true,
            type: 'application/x-mpegURL'
          }

          this.player.src(options);
          this.player.load();
        }
      }
    }
  }


</script>

<style scoped lang="scss">
.activeBtn { color: red; }

.video_box {
  width: 100%;
  height: 100%;
  position: relative;
  // border: 1px solid red;
  .video_view { width: 100%; height: 100%; }

  .m3u8_box {
    width: 100%;
    height: 100%;
  }

  .btn_box {
    padding: 0 5px;
    position: absolute;
    top: 30px;
    right: -150px;
    font-size: 20px;

    .choose_btn {
      padding: 5px;
      border: none;
      border-radius: 3px; 
      margin-bottom: 3px; 
      margin-right: 5px;
      background-color: #212121;
      color: #fff;
    }
    .choose_btn:hover { cursor: pointer; }

    .play_btn { 
      padding: 5px;
      border: none;
      border-radius: 3px; 
      margin-bottom: 3px; 
      margin-right: 5px;
    }
    .play_btn:hover { cursor: pointer; }
  }

  .videoListBox {
    width: 50%;
    height: 90%;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #eee;
    background-color: rgba(#212121, 0.8);
    color: #fff;
    border-radius: 5px;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    .video_item {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
    .video_item:hover { cursor: pointer; }
  }
}
</style>

<style>
.el-tabs__item { color: #fff !important; text-align: center !important; }
.el-tabs__item.is-active { color: #409eff !important; }
</style>

